const url = "https://nodepune-sniperbot.mobiloitte.io"; // staging
const botSok = "https://pypune-arbitragebot.mobiloitte.io"; // staging

// const url = "https://no.mbaacademy.asia";// client live url
// const botSok = "https://python.mbaacademy.asia"; // client live url

// const url = "http://172.16.6.80:3053" // jayesh  local
// const url = "http://172.16.2.14:3053" // suraj  local
// const url  = "http://172.16.2.16:3053"  // Rohit sir  local

const ApiConfig = {
  botSok,
  login: `${url}/api/v1/user/login`,
  loginadmin: `${url}/api/v1/admin/login`,
  signup: `${url}/api/v1/user/register`,
  verify: `${url}/api/v1/user/verify`,
  verifyOTP: `${url}/api/v1/admin/verifyOTP`,
  forgot: `${url}/api/v1/user/forgot`,
  forgotPassword: `${url}/api/v1/admin/forgotPassword`,
  changePassword: `${url}/api/v1/user/changePassword`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  changePasswordAdmin: `${url}/api/v1/admin/changePassword`,
  resend: `${url}/api/v1/user/resend`,
  resendOTP: `${url}/api/v1/admin/resendOTP`,
  profile: `${url}/api/v1/user/profile`,
  getProfile: `${url}/api/v1/admin/getProfile`,
  editProfile: `${url}/api/v1/user/editProfile`,
  adminEditProfile: `${url}/api/v1/admin/editProfile`,
  uploadImage: `${url}/api/v1/upload/uploadImage`,
  userSniperList: `${url}/api/v1/admin/userSniperList`,
  viewSniperUser: `${url}/api/v1/admin/viewSniperUser`,
  kyclist: `${url}/api/v1/admin/kyc/list`,
  viewKyc: `${url}/api/v1/admin/kyc/view`,
  approveOrReject: `${url}/api/v1/admin/kyc/approveOrReject`,
  listUser: `${url}/api/v1/admin/listUser`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  createPlan: `${url}/api/v1/plan/create`,
  editPlan: `${url}/api/v1/plan/edit`,
  viewPlan: `${url}/api/v1/plan/view`,
  Planlist: `${url}/api/v1/plan/list`,
  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  delete: `${url}/api/v1/plan/delete/`,
  affiliationLevel:`${url}/api/v1/admin/updatemlmBounce`,
  viewAffiliationLevel:`${url}/api/v1/admin/viewmlmBounce`,

  // Bot settings
  coinPairData:`${url}/api/v1/admin/coinPairData`,
  getBotStatus:`${url}/api/v1/admin/getBotStatus`,
  runScript:`${url}/api/v1/admin/runScript`,
  stopScript:`${url}/api/v1/admin/stopScript`,
  addCoins:`${url}/api/v1/admin/addCoins`,
  viewCoins:`${url}/api/v1/admin/viewCoins`,
  deleteParticularCoin:`${url}/api/v1/admin/deleteParticularCoin`,
  coinStatus:`${url}/api/v1/admin/coinStatus`,
  
// transaction History
viewUserTransactionHistory:`${url}/api/v1/admin/viewUserTransactionHistory`,

  //list all level bonus
  listAllWorkingPlanUsers: `${url}/api/v1/admin/listAllWorkingPlanUsers`,
  getAffiliateTreeUsers:`${url}/api/v1/admin/getAffiliateTreeUsers`,
  payoutUserLevel:`${url}/api/v1/admin/payoutUserLevel`,

  //list All PreLaunching Bouns
  listAllPreLaunchingBouns: `${url}/api/v1/admin/listAllPreLaunchingBouns`,

  //list All salary  Bouns
  listAllSalaryBouns: `${url}/api/v1/admin/listAllSalaryBouns`,
  payoutSalaryOffer: `${url}/api/v1/admin/payoutSalaryOffer`,


  //  list All Reward Bonus
  listAllRewardBouns: `${url}/api/v1/admin/listAllRewardBouns`,
  payoutRewardOffer:`${url}/api/v1/admin/payoutRewardOffer`,

  // List Salary Configuration
  listSalaryTarget:`${url}/api/v1/admin/listSalaryTarget`,
  viewSalaryTarget:`${url}/api/v1/admin/viewSalaryTarget`,
  updateSalaryTarget:`${url}/api/v1/admin/updateSalaryTarget`,

  //  List Reward Configuration
  listRewardTarget:`${url}/api/v1/admin/listRewardTarget`,
  viewRewardTarget:`${url}/api/v1/admin/viewRewardTarget`,
  updateRewardTarget:`${url}/api/v1/admin/updateRewardTarget`,

  boutghtcoins: `${botSok}/boutghtcoins`,
  sellcoins: `${botSok}/sellcoins`,
  keepholding: `${botSok}/keepholding`,
  adminDashboard: `${url}/api/v1/admin/adminDashboard`, //node
  pyAdminDashboard: `${botSok}/dailyLiveStatusReport`, //python

  // subscription management
  subscriptionPlanList: `${url}/api/v1/plan/list`,
  subscriptionPlanView: `${url}/api/v1/plan/view`,
  subscriptionPlanEdit: `${url}/api/v1/plan/edit`,

  //admin update wallet
  updateWalletAddress: `${url}/api/v1/admin/updateWalletAddress`,

  //get admin wallet
  getWalletAddress: `${url}/api/v1/admin/getWalletAddress`,
  updateSecretKey: `${url}/api/v1/admin/updateSecretKey`,


  //reset trade count
  updateTradeCountCollection:`${url}/api/v1/admin/updateTradeCountCollection`,

  //Threshold
  thresholdList: `${url}/api/v1/admin/thresholdList`,
  viewThreshold: `${url}/api/v1/admin/viewThreshold`,
  updateThreshold: `${url}/api/v1/admin/updateThreshold`,

  //trading bonus
  getUserTradingIncomeList:`${url}/api/v1/admin/getUserTradingIncomeList`,
  payTradingBonus:`${url}/api/v1/admin/payTradingBonus`
};
export default ApiConfig;
